  /* eslint-disable no-unused-vars */
  import React, { useState,useEffect,useRef } from 'react';
  import { useSpring, animated } from 'react-spring';
  import './Services.css';

  export const Services = () => {
    const [showRectangles, setShowRectangles] = useState(false);

    const rectanglesSpring = useSpring({
      opacity: showRectangles ? 1 : 0,
      transform: showRectangles ? "translate3d(0,0,0)" : "translate3d(0,100px,0)",
      delay: 400, 
    });

    useEffect(() => {
      setShowRectangles(true);
    }, []);

  

    const [rectangles, setRectangles] = useState([
      { id: 1, text: 'УГОЛОВНЫЕ ДЕЛА',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="80" viewBox="0 96 960 960" width="80"><path d="M769 968 645 844l-88 88-43-43q-17-17-17-42t17-42l199-199q17-17 42-17t42 17l43 43-88 88 123 124q9 9 9 21t-9 21l-64 65q-9 9-21 9t-21-9Zm111-636L427 785l19 20q17 17 17 42t-17 42l-43 43-88-88-124 124q-9 9-21 9t-21-9l-65-65q-9-9-9-21t9-21l124-124-88-88 43-43q17-17 42-17t42 17l20 19 453-453h160v160ZM320 488l38-38 38-38-38 38-38 38Zm-42 42L80 332V172h160l198 198-42 42-181-180h-75v75l180 181-42 42Zm105 212 437-435v-75h-75L308 667l75 75Zm0 0-37-38-38-37 38 37 37 38Z"  fill="#131053"/></svg>' },
      { id: 2, text: 'ГРАЖДАНСКИЕ СПОРЫ', 
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="90" viewBox="0 96 960 960" width="90"><path d="M0 816v-53q0-38.567 41.5-62.784Q83 676 150.376 676q12.165 0 23.395.5Q185 677 196 678.652q-8 17.348-12 35.165T180 751v65H0Zm240 0v-65q0-32 17.5-58.5T307 646q32-20 76.5-30t96.5-10q53 0 97.5 10t76.5 30q32 20 49 46.5t17 58.5v65H240Zm540 0v-65q0-19.861-3.5-37.431Q773 696 765 678.727q11-1.727 22.171-2.227 11.172-.5 22.829-.5 67.5 0 108.75 23.768T960 763v53H780Zm-480-60h360v-6q0-37-50.5-60.5T480 666q-79 0-129.5 23.5T300 751v5ZM149.567 646Q121 646 100.5 625.438 80 604.875 80 576q0-29 20.562-49.5Q121.125 506 150 506q29 0 49.5 20.5t20.5 49.933Q220 605 199.5 625.5T149.567 646Zm660 0Q781 646 760.5 625.438 740 604.875 740 576q0-29 20.562-49.5Q781.125 506 810 506q29 0 49.5 20.5t20.5 49.933Q880 605 859.5 625.5T809.567 646ZM480 576q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600 456q0 50-34.5 85T480 576Zm.351-60Q506 516 523 498.649t17-43Q540 430 522.851 413t-42.5-17Q455 396 437.5 413.149t-17.5 42.5Q420 481 437.351 498.5t43 17.5ZM480 756Zm0-300Z"  fill="#131053"/></svg>' },
      { id: 3, text: 'АДМИНИСТРАТИВНЫЕ ДЕЛА',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="80" viewBox="0 96 960 960" width="80"><path d="M160 936v-60h480v60H160Zm222-212L160 502l70-72 224 222-72 72Zm254-254L414 246l72-70 222 222-72 72Zm202 426L302 360l42-42 536 536-42 42Z"  fill="#131053"/></svg>' },
      { id: 4, text: 'ЗАЩИТА В ВОЕННЫХ СУДАХ',
      icon:'<svg xmlns="http://www.w3.org/2000/svg" height="90" viewBox="0 96 960 960" width="90"><path d="M280 176h400v333q0 23-11.316 42.149T637 582l-141 82 26 97h134l-109 81 42 134-109-81-110 81 42-134-109-81h135.111L463 664l-140-82q-20.368-11.702-31.684-30.851Q280 532 280 509V176Zm60 60v273q0 7 4.5 13t13.5 11l96 53V236H340Zm280 0H514v350l88-53q9-5 13.5-11t4.5-13V236ZM484 419Zm-30-8Zm60 0Z" fill="#131053"/></svg>'},
      { id: 5, text: 'АВТОЮРИСТ',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="80" viewBox="0 96 960 960" width="80"><path d="M320 328 214 222l42-42 106 106-42 42Zm320 0-42-42 106-106 42 42-106 106Zm-190-52V96h60v180h-60Zm-300 780q-12.75 0-21.375-8.62Q120 1038.75 120 1026V702l85-256q5-14 16.5-22t26.5-8h464q15 0 26.5 8t16.5 22l85 256v324q0 12.75-8.625 21.38Q822.75 1056 810 1056h-21q-13 0-21-8.62-8-8.63-8-21.38v-54H200v54q0 12.75-8.625 21.38Q182.75 1056 170 1056h-20Zm53-414h554l-55-166H258l-55 166Zm-23 270V702v210Zm105.765-50Q309 862 324.5 846.25T340 808q0-23.333-15.75-39.667Q308.5 752 286 752q-23.333 0-39.667 16.265Q230 784.529 230 807.765 230 831 246.265 846.5q16.264 15.5 39.5 15.5ZM675 862q23.333 0 39.667-15.75Q731 830.5 731 808q0-23.333-16.265-39.667Q698.471 752 675.235 752 652 752 636.5 768.265q-15.5 16.264-15.5 39.5Q621 831 636.75 846.5T675 862Zm-495 50h600V702H180v210Z"  fill="#131053"/></svg>' },
      { id: 6, text: 'ЗЕМЕЛЬНЫЕ СПОРЫ', 
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="80" viewBox="0 96 960 960" width="80"><path d="M480 897q133-121 196.5-219.5T740 504q0-117.79-75.292-192.895Q589.417 236 480 236t-184.708 75.105Q220 386.21 220 504q0 75 65 173.5T480 897Zm0 79Q319 839 239.5 721.5T160 504q0-150 96.5-239T480 176q127 0 223.5 89T800 504q0 100-79.5 217.5T480 976ZM370 616h60V496h100v120h60V431l-110-73-110 73v185Zm110-112Z"  fill="#131053"/></svg>' },
      { id: 7, text: 'НАЛОГОВЫЕ ДЕЛА',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="80" viewBox="0 96 960 960" width="85"><path d="M540 636q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM220 776q-24.75 0-42.375-17.625T160 716V316q0-24.75 17.625-42.375T220 256h640q24.75 0 42.375 17.625T920 316v400q0 24.75-17.625 42.375T860 776H220Zm100-60h440q0-42 29-71t71-29V416q-42 0-71-29t-29-71H320q0 42-29 71t-71 29v200q42 0 71 29t29 71Zm480 180H100q-24.75 0-42.375-17.625T40 836V376h60v460h700v60ZM220 716V316v400Z"  fill="#131053"/></svg>' },
      { id: 8, text: 'НАСЛЕДСТВЕННЫЕ СПОРЫ',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="80" viewBox="0 96 960 960" width="80"><path d="M160 936V535l-84 64-36-48 120-91V346h60v68l260-198 440 336-36 47-84-64v401H160Zm60-60h230V716h60v160h230V489L480 291 220 489v387Zm-60-580q0-46 32.5-78t77.5-32q21.25 0 35.625-15T320 136h60q0 45-32.083 77.5Q315.833 246 270 246q-20 0-35 14.375T220 296h-60Zm60 580h520-520Z"  fill="#131053"/></svg>' },
      { id: 9, text: 'ПОДГОТОВКА ПРАВОВЫХ ДОКУМЕНТОВ', 
      icon:'<svg xmlns="http://www.w3.org/2000/svg" height="80" viewBox="0 96 960 960" width="80"><path d="M319 806h322v-60H319v60Zm0-170h322v-60H319v60Zm-99 340q-24 0-42-18t-18-42V236q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm331-554V236H220v680h520V422H551ZM220 236v186-186 680-680Z"  fill="#131053"/></svg>' },
    ]);
    
    return (
      <div className="services" id='servicess'>
        <div className="rectangle-grid">
          {rectangles.map(rectangle => (
            <animated.div key={rectangle.id} className="rectangle" style={rectanglesSpring}>
              <div className="rectangle__inner"></div>
              <div className="icon" dangerouslySetInnerHTML={{ __html: rectangle.icon }}></div>
              <div className="text">{rectangle.text}</div>
            </animated.div>
          ))}
        </div>
      </div>
    );
  };