/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './Modal.css';

export const Modal = ({ showModal, handleClose }) => {
  const [images, setImages] = useState([
    {
      original: 'img/1.jpg',
      thumbnail: 'img/1.jpg',
    },
    {
      original: 'img/2.jpg',
      thumbnail: 'img/2.jpg',
    },
    {
    original: 'img/certificate 2_page-0001.jpg',
    thumbnail: 'img/certificate 2_page-0001.jpg',
    },
    {
      original: 'img/certificate 3_page-0001.jpg',
      thumbnail: 'img/certificate 3_page-0001.jpg',
    },
    {
      original: 'img/certificate 5_page-0001.jpg',
      thumbnail: 'img/certificate 5_page-0001.jpg',
    },
    {
      original: 'img/certificate 6_page-0001.jpg',
      thumbnail: 'img/certificate 6_page-0001.jpg',
    },
    {
      original: 'img/certificate 8_page-0001.jpg',
      thumbnail: 'img/certificate 8_page-0001.jpg',
    },
    {
      original: 'img/certificate 9_page-0001.jpg',
      thumbnail: 'img/certificate 9_page-0001.jpg',
    },
    {
      original: 'img/certificate 10_page-0001.jpg',
      thumbnail: 'img/certificate 10_page-0001.jpg',
    },
    {
      original: 'img/certificate 11_page-0001.jpg',
      thumbnail: 'img/certificate 11_page-0001.jpg',
    },
    {
      original: 'img/certificate_page-0001.jpg',
      thumbnail: 'img/certificate_page-0001.jpg',
    },
  ]);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleClose]);

  if (!showModal) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={handleClose}>
          &times;
        </span>
        <ImageGallery
        items={images}
        originalClass={'image-gallery-image'}/>
      </div>
    </div>
  );
};