import React, { useState, useEffect } from "react";
import "./App.css";
import { Navbar } from "./components/Navbar/Navbar";
import { Map } from "../src/components/Footers/Сontacts/Сontacts";
import { Main } from "./components/Main/Firstpage/Main";
import { NumberBox } from "./components/Number/Number";
import { Services } from "./components/Legal_Services/Services";
import { About } from "./components/About/About";
import { ScrollToTopButton } from "./components/Footers/ScrollToTopButton/ScrollToTopButton";
import { css } from "@emotion/react";
import { BounceLoader } from "react-spinners";

function App() {
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div className="App">
      {loading ? (
        <div className="loader">
          <BounceLoader color={"blue"} loading={loading} css={override} size={120} />
        </div>
      ) : (
        <>
          <Navbar />
          <Main />
          <NumberBox />
          <About />
          <Services />
          <Map />
          <ScrollToTopButton />
        </>
      )}
    </div>
  );
}

export default App;