import React, { useState } from 'react';
import './ScrollToTopButton.css';

export function ScrollToTopButton() {
  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 550) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  window.addEventListener('scroll', handleScroll);

  return (
<button
  className={`scroll-to-top-button ${showScrollButton ? 'show' : ''}`}
  onClick={scrollToTop}
>
  <svg xmlns="http://www.w3.org/2000/svg" height="55" viewBox="0 96 960 960" width="55">
    <path d="m242 618-42-42 280-280 280 280-42 42-238-237-238 237Z" fill="#fff"/>
  </svg>
</button>
  );
}