import React from 'react';
import './CallCompon.css';
import { SocialButtons } from '../SocialButtons/SocialButtons';

export function CallCompon() {
  const phoneNumber = '+79787401648';

  function handleCallClick() {
    var newWindow = window.open(`tel:${phoneNumber}`, '_self');
    newWindow.focus();
  }

  return (
    <div className='contact'>
      
      
    <div className='call-social-buttons'>
      <div className='ft'>
    <h1>Губенко Кирилл Сергеевич</h1>
    <div className="line"></div>
    </div>
      <p className='grey'>
        Регистрационный номер в реестре адвокатов<br/>
        Республики Крым: 90/911
      </p>
      <p className='black'>
        Адрес: Республика Крым, г.Cимферополь,<br/>
        ул.Набережная имени 60-летия СССР, 50 корп.4 пом.2 <br/>
        sudps.krym@mail.ru
      </p>
  </div>
      <button className='phone-button' onClick={handleCallClick}>
        +7 (978) 740-16-48
      </button>
      <div className='textе'>
        <p>Связаться со мной в соцсетях:</p>
      <SocialButtons />
      </div>
    </div>
  );
}