import React, { useState, useEffect } from 'react';
import './Navbar.css';

export const Navbar = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [prevScrollpos, setPrevScrollpos] = useState(0);
  const [visible, setVisible] = useState(true);

  const scrollToComponent = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
  };

  const toggleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  const navClass = isNavCollapsed ? "navbox" : "navbox-expanded";

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollpos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollpos(currentScrollPos);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollpos]);

  const navStyle = {
    top: visible ? '0' : '-100px',
    transition: 'top 0.6s ease-in-out'
  };

  return (
    <nav className={navClass} style={navStyle}>
      <ul>
        <li><a style={{ fontFamily: 'Montserrat' }} onClick={() => scrollToComponent('about')}>О себе</a></li>
        <li><a style={{ fontFamily: 'Montserrat' }} onClick={() => scrollToComponent('servicess')}>Адвокатские услуги</a></li>
        <li><a style={{ fontFamily: 'Montserrat' }} onClick={() => scrollToComponent('contact')}>Контакты</a></li>
      </ul>
      <button className="menu-icon" onClick={toggleNavCollapse}>
        <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48">
          <path d="M120 816v-60h720v60H120Zm0-210v-60h720v60H120Zm0-210v-60h720v60H120Z" />
        </svg>
      </button>
    </nav>
  );
};