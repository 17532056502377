/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import './About.css';
import { Modal } from '../Main/Modal/Modal';
import fhoto from './1.jpg';

export function About() {
  const [showModal, setShowModal] = useState(false);
  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="mainboxabout" id="about">
      <div className='top'>
        <div className="lin"></div>
        <h1 className='top_text'>ОБ АДВОКАТЕ</h1>
      </div>
      <div className='main_text'>
        <p>Если вы ищете надежного и опытного адвоката, который может помочь вам
           в сложных правовых вопросах, то я могу стать вашим надежным партнером.</p>
      </div>
    <div className='fotoabout'>
    <img className='imgg' src={fhoto} alt="Photo"  />
    </div>
    <div className='box_one'>
      <div className='btnnbox'>
       <button className="btn" onClick={handleButtonClick}>
       Удостоверение адвоката
     </button></div>
    </div>
    <div className='box_two'>
    <div className='text_about'>
     <div>
    <h3>- ОПЫТ -</h3>
    <p className='abb'>Практикую более 10 лет в области юриспруденции. Я уверен в своих знаниях и навыках, и знаю как защитить Ваши интересы в суде.</p>
  </div>
  <div>
    <h3>- ИНДИВИДУАЛЬНЫЙ ПОДХОД -</h3>
    <p className='abb'>У клиентов свои особенности и уникальные потребности. Поэтому в процессе работы я предлагаю наилучшее решение для каждой ситуации.</p>
  </div>
  <div>
    <h3>- ПРОЗРАЧНОСТЬ -</h3>
    <p className='abb'>Всегда держу Вас в курсе прогресса в деле, а также готов ответить на все возникающие вопросы.</p>
  </div>
  <div>
    <h3>- ДОСТУПНОСТЬ -</h3>
    <p className='abb'>Ценю Ваше время и уважаю Вашу занятость, поэтому стараюсь находить удобное время для встреч и консультаций.</p>
  </div>
  <div>
    <h3>- ЦЕЛЕУСТРЕМЛЕННОСТЬ -</h3>
    <p className='abb'>Беру на себя ответственность за каждое дело и уверен, что могу достичь желаемого результата.</p>
  </div>
  </div>
    </div>
    <Modal showModal={showModal} handleClose={handleCloseModal} />
</div>
  );
}