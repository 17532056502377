/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import './Number.css';


const animationConfigs = [
  { duration: 2000 },
  { duration: 2500 },
  { duration: 3000 },
];

export const NumberBox = () => {
  const numbers = [
    { value: 10, symbol: 'лет' },
    { value: 57, symbol: '' },
    { value: 100, symbol: '%' },
  ];
  const descriptions = [
    "УСПЕШНОЙ ЮРИДИЧЕСКОЙ ПРАКТИКИ",
    "СРЕДНЕЕ КОЛИЧЕСТВО ДЕЛ В ГОД",
    " ПОГРУЖЕНИЕ В ДЕЛО"
  ];
  const [isInView, setIsInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      {
        root: null,
        threshold: 0.5 ,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
   <div className='mainbox'>
    <div className='lines'></div>
    <div className='numberbox' ref={ref}>
      {numbers.map((number, index) => (
        <div className="number-container" key={index}>
          {isInView && <CircleNumber number={number} config={animationConfigs[index]} />}
          <div className="description-container">
            <p >{descriptions[index]}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
  );
};

const CircleNumber = ({ number, config }) => {
  const [count, setCount] = useState(0);
  const { value } = useSpring({
    value: count,
    from: { value: 0 },
    to: { value: number.value },
    config,
    onRest: () => setCount(number.value),
  });

  useEffect(() => {
    setCount(number.value);
  }, [number.value]);

  return (
    <animated.div className="circle-number">
      {value.to(val => `${Math.floor(val)} ${number.symbol}`)}
    </animated.div>
  );
};