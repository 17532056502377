/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import './Contacts.css';
import { CallCompon } from '../СallCompon/СallCompon';
import { Footer } from '../Footer/Footer'


export const Map = () => {
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://api-maps.yandex.ru/2.1/?apikey=1760bfd3-fa04-47c0-82de-c8d1cedd7219&lang=ru_RU';
    script.async = true;
    script.onload = () => setMapLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);
  useEffect(() => {
    if (mapLoaded) {
      ymaps.ready(() => {
        const myMap = new ymaps.Map('map', {
          center: [44.960261, 34.096868], 
          zoom: 17,
          controls: ['zoomControl', 'typeSelector', 'fullscreenControl']
        });

        const myPlacemark = new ymaps.Placemark([44.960261, 34.096868], {
          hintContent: 'ул.Набережная имени 60-летия СССР, 50 корп.4 пом.2'
        });

        myMap.geoObjects.add(myPlacemark);
      });
    }
  }, [mapLoaded]);
  return (
    <div>
    <div className='four_compon'id='contact'>
    <div className='contactbox'>
      <div className='btnnumbr'>
        <CallCompon />
      </div>
      <div id='map'  className='maps'></div>
       </div>
    </div>
    <div className='foot'>
       <Footer/>
       </div>
    </div>
  );
};