import React, { useState, useEffect } from 'react';
import { SocialButtons } from '../../Footers/SocialButtons/SocialButtons';
import { useSpring, animated } from 'react-spring';
import './Main.css';
import photoo from './2.jpg';


export const Main = () => {
  const phoneNumber = '+79787401648';

  function handleCallClick() {
    var newWindow = window.open(`tel:${phoneNumber}`, '_self');
    newWindow.focus();
  }
  const { opacity, transform, config, delay } = {
    opacity: 0,
    transform: 'translateY(150px)',
    config: { mass: 2, tension: 20, friction: 1, duration:1200 }, 
    delay: 300, 
  };

  const [isAnimated, setIsAnimated] = useState(false);
  
const textAnimation = useSpring(isAnimated ? { opacity: 1, transform: 'translateY(0)', onRest: () => setIsAnimated(true), config, delay } : { opacity, transform, config, delay });
const buttonAnimation = useSpring(isAnimated ? { opacity: 1, transform: 'translateY(0)',onRest: () => setIsAnimated(true), config, delay} : { opacity, transform: 'translateY(-150px)', delay});
const socialButtonsAnimation = useSpring(isAnimated ? { opacity: 1, transform: 'translateY(0)',onRest: () => setIsAnimated(true), config, delay} : { opacity, transform, delay});


  useEffect(() => {
    setIsAnimated(true);
  }, []);

  return (
    <div className="first_compon">
      <img
        src={photoo}
        alt="Адвокат Симферополь"
        className="bg-img"
        loading="eager"
      ></img>
      <div className="box">
        <animated.div style={textAnimation} className="sometext">
          <h1 className="name">
            АДВОКАТ
            <br /> ГУБЕНКО КИРИЛЛ СЕРГЕЕВИЧ
          </h1>
        </animated.div>
        <div className="firsttext">
          <animated.div style={textAnimation} className="linex"></animated.div>
          <animated.div style={textAnimation} className="description">
            <p>
              Оказываю профессиональную помощь в решении широкого спектра правовых вопросов.
            </p>
          </animated.div>
          <animated.div style={textAnimation} className="description_job">
            <p>
              Являюсь членом Коллегии адвокатов Республики Крым «Первая Крымская».
            </p>
          </animated.div>
          <animated.div style={textAnimation} className="experience">
            <p>Опыт работы в правовой сфере составляет более 10 лет.</p>
          </animated.div>
        </div>
      </div>
      <div className="btnbox">
        <animated.button
          style={buttonAnimation}
          className="btns"
          onClick={handleCallClick}
          disabled={!isAnimated}
        >
          +7 (978) 740-16-48
        </animated.button>
       < animated.div style={socialButtonsAnimation} className="btnsoc">
          <SocialButtons />
        </animated.div>
      </div>
      <div className="background"></div>
    </div>
  );
};