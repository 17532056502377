import React from 'react';
import './Footer.css';

export function Footer() {
  return (
    <footer>
      <div className='container'>
      <hr className="hr"></hr>
   <p className='text-foot'>
    &copy; 2023 Адвокат в Симферополе Кирилл Губенко.<br/>
    Оказание юридических услуг в Республике Крым и г. Симферополь.
   </p>
        </div>
    </footer>
  );
}